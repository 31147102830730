import { Box, BoxHeader } from '../../general/Box';
import { Page } from '../../general/Page';
import { BoxForm } from '../../general/BoxForm';
import { FormField } from '../../general/form/Form';
import { useCallback, useEffect, useState } from 'react';
import { Table } from '../../general/Table';
import { advancedSearchService } from '../../../services/advancedSearch.service';
import { Spinner } from '../../general/Spinner';
import { CellProps } from 'react-table';
import { OpenIconLink } from '../../general/OpenIconLink';
import {
    getMissionAdminFunctionLabel,
    MissionCIxAdminFunctionList,
    MissionFinancingFunctionAdminList
} from '../../../enums/mission-admin-function.enum';
import { useAdmins } from '../../../hooks/use-admins';

export type AdvancedSearchQueryColumnValue = {
    columnKey: string,
    columnValue: string
}
export type AdvancedSearchQuery = {
    type: string | undefined,
    columns: AdvancedSearchQueryColumnValue[] | []
}
export type FilterType = {
    value: string;
    label: string;
    type?: string;
    options?: any[];
    placeholder?: string;
};

export const AdvancedSearchPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [results, setResults] = useState([] as any[]);
    const { admins } = useAdmins() as { admins: any[] };

    const [firstSearchDone, setFirstSearchDone] = useState(false);

    const configurations = {
        suivi_missions_cir_cii: {
            filters: [
                { value: 'year', label: 'Année', type: 'text' },
                {
                    value: 'status',
                    label: 'Status',
                    type: 'select',
                    placeholder: 'Choisir...',
                    options: [{ value: 'in_progress', label: 'En cours' }, { value: 'done', label: 'Terminé' }]
                },
                {
                    value: 'collab_choice',
                    label: 'Collab',
                    placeholder: 'Choisir...',
                    type: 'select',
                    options: (admins ?? []).map(admin => ({
                        value: admin.id,
                        label: `${admin.firstname} ${admin.lastname}`
                    }))
                },
                {
                    value: 'collab_role',
                    label: 'Collab - Rôle',
                    placeholder: 'Choisir...',
                    type: 'select',
                    options: MissionCIxAdminFunctionList.map(role => ({
                        value: role,
                        label: getMissionAdminFunctionLabel(role)
                    }))
                },

            ],
            resultsTableColumns: [
                { Header: 'Client', accessor: 'client_name' },
                { Header: 'Mission', accessor: 'mission_name' },
                { Header: 'Année', accessor: 'year' },
                {
                    Header: () => null,
                    id: 'actions',
                    Cell: ({ row: { original: item } }: CellProps<any>) => <OpenIconLink newTab={true} to={`/clients/${item.client_id}/missions/${item.mission_id}`} title="Détails"/>
                }
            ]
        },
        suivi_missions_fnd: {
            type: 'suivi_missions_fnd',
            filters: [
                { value: 'year', label: 'Année', type: 'text' },
                {
                    value: 'status',
                    label: 'Status',
                    type: 'select',
                    placeholder: 'Choisir...',
                    options: [{ value: 'in_progress', label: 'En cours' }, { value: 'done', label: 'Terminé' }]
                },
                {
                    value: 'collab_choice',
                    label: 'Collab',
                    placeholder: 'Choisir...',
                    type: 'select',
                    options: (admins ?? []).map(admin => ({
                        value: admin.id,
                        label: `${admin.firstname} ${admin.lastname}`
                    }))
                },
                {
                    value: 'collab_role',
                    label: 'Collab - Rôle',
                    placeholder: 'Choisir...',
                    type: 'select',
                    options: MissionFinancingFunctionAdminList.map(role => ({
                        value: role,
                        label: getMissionAdminFunctionLabel(role)
                    }))
                },

            ],
            resultsTableColumns: [
                { Header: 'Client', accessor: 'client_name' },
                { Header: 'Mission', accessor: 'mission_name' },
                { Header: 'Année', accessor: 'year' },
                {
                    Header: () => null,
                    id: 'actions',
                    Cell: ({ row: { original: item } }: CellProps<any>) => <OpenIconLink newTab={true} to={`/clients/${item.client_id}/missions/${item.mission_id}`} title="Détails"/>
                }
            ]
        },
        suivi_missions_others: {
            filters: [
                { value: 'year', label: 'Année', type: 'text' },
                {
                    value: 'status',
                    label: 'Status',
                    type: 'select',
                    placeholder: 'Choisir...',
                    options: [{ value: 'in_progress', label: 'En cours' }, { value: 'done', label: 'Terminé' }]
                },
                {
                    value: 'collab_choice',
                    label: 'Collab',
                    placeholder: 'Choisir...',
                    type: 'select',
                    options: (admins ?? []).map(admin => ({
                        value: admin.id,
                        label: `${admin.firstname} ${admin.lastname}`
                    }))
                },
                {
                    value: 'collab_role',
                    label: 'Collab - Rôle',
                    placeholder: 'Choisir...',
                    type: 'select',
                    options: MissionFinancingFunctionAdminList.map(role => ({
                        value: role,
                        label: getMissionAdminFunctionLabel(role)
                    }))
                },
            ],
            resultsTableColumns: [
                { Header: 'Client', accessor: 'client_name' },
                { Header: 'Mission', accessor: 'mission_name' },
                { Header: 'Année', accessor: 'year' },
                {
                    Header: () => null,
                    id: 'actions',
                    Cell: ({ row: { original: item } }: CellProps<any>) => <OpenIconLink newTab={true} to={`/clients/${item.client_id}/missions/${item.mission_id}`} title="Détails"/>
                }
            ]
        },
        suivi_customer_team: {
            filters: [
                { value: 'year', label: 'Année', type: 'text' },
                {
                    value: 'status',
                    label: 'Status Mission',
                    type: 'select',
                    placeholder: 'Choisir...',
                    options: [{ value: 'in_progress', label: 'En cours' }, { value: 'done', label: 'Terminé' }]
                }
            ],
            resultsTableColumns: [
                { Header: 'Client', accessor: 'client_name' },
                { Header: 'Mission', accessor: 'mission_name' },
                { Header: 'Année', accessor: 'year' },
                { Header: 'Personne', accessor: 'fullname' },
                { Header: 'Rôle', accessor: 'role' },
                {
                    Header: () => null,
                    id: 'actions',
                    Cell: ({ row: { original: item } }: CellProps<any>) => <OpenIconLink newTab={true} to={`/clients/${item.client_id}/missions/${item.mission_id}`} title="Détails"/>
                }
            ]
        },
        suivi_invoicing: {
            filters: [
                { value: 'year', label: 'Année', type: 'text' },
                {
                    value: 'status',
                    label: 'Status Mission',
                    type: 'select',
                    placeholder: 'Choisir...',
                    options: [{ value: 'in_progress', label: 'En cours' }, { value: 'done', label: 'Terminé' }]
                }
            ],
            resultsTableColumns: [
                { Header: 'Client', accessor: 'client_name' },
                { Header: 'Mission', accessor: 'mission_name' },
                { Header: 'Année', accessor: 'year' },
                { Header: 'Facture', accessor: 'invoice_number' },
                {
                    Header: () => null,
                    id: 'actions',
                    Cell: ({ row: { original: item } }: CellProps<any>) => <OpenIconLink newTab={true} to={`/clients/${item.client_id}/missions/${item.mission_id}`} title="Détails"/>
                }
            ]
        },
        users: {
            filters: [
                { value: 'firstname', label: 'Prénom', type: 'text' },
                { value: 'lastname', label: 'Nom', type: 'text' },
                { value: 'email', label: 'Email', type: 'text' },
            ],
            resultsTableColumns: [
                { Header: 'Société', accessor: 'client.name' },
                { Header: 'Nom', accessor: 'lastname', disableSortBy: true },
                { Header: 'Prénom', accessor: 'firstname', disableSortBy: true },
                { Header: 'Email', accessor: 'email', disableSortBy: true },
                {
                    Header: () => null,
                    id: 'actions',
                    Cell: ({ row: { original: user } }: CellProps<any>) => <OpenIconLink newTab={true}
                                                                                         to={`/clients/${user.client.id}/users/${user.id}`} title="Détails"/>
                }
            ]
        },
        missions: {
            filters: [
                { value: 'name', label: 'Nom de mission', type: 'text' },
                { value: 'customer_name', label: 'Nom du client', type: 'text' },
                { value: 'deadline_date', label: 'Date d\'échéance', type: 'text' },
                { value: 'end_date', label: 'Date de fin', type: 'text' },
                { value: 'panda_contact_name', label: 'Contact Panda', type: 'text' },
            ],
            resultsTableColumns: [
                { Header: 'Société', accessor: 'client.name' },
                { Header: 'Nom', accessor: 'name', disableSortBy: true },
                {
                    Header: () => null,
                    id: 'actions',
                    Cell: ({ row: { original: mission } }: CellProps<any>) => <OpenIconLink newTab={true} to={`/clients/${mission.client.id}/missions/${mission.id}`} title="Détails"/>
                }
            ]
        }
    };

    const [searchQuery, setSearchQuery] = useState<AdvancedSearchQuery>({
        type: undefined,
        columns: [],
    });

    const baseFields: FormField[] = [
        {
            name: 'type',
            label: 'Type',
            registerOptions: { required: true },
            type: 'select',
            placeholder: 'Choisir...',
            options: [
                // { value: 'customers', label: 'Clients' },
                // { value: 'missions', label: 'Missions' },
                // { value: 'users', label: 'Utilisateurs' },
                { value: 'suivi_missions_cir_cii', label: 'Suivi missions CIR/CII' },
                { value: 'suivi_missions_fnd', label: 'Suivi missions FND' },
                { value: 'suivi_missions_others', label: 'Suivi missions autres' },
                { value: 'suivi_customer_team', label: 'Suivi client-équipe' },
                { value: 'suivi_invoicing', label: 'Suivi Facturation' },
            ],
            cols: 2,
            emptyCols: 6,
            onChange: (value) => {
                setSearchQuery(prevSearchQuery => ({ ...prevSearchQuery, type: value, columns: [] }));
            }
        }
    ];
    const [filtersFields, setFiltersFields] = useState<FormField[]>([]);

    const submit = useCallback((data: any): any => {
        setLoading(true);

        if (typeof searchQuery.columns !== 'undefined') {
            let collabChoice = null;
            let collabRole = null;

            const columns: AdvancedSearchQueryColumnValue[] = searchQuery.columns;

            columns.forEach((column: any) => {
                if (column.columnKey === 'collab_choice' && column.columnValue.length > 0) {
                    collabChoice = column.columnValue;
                }
                if (column.columnKey === 'collab_role' && column.columnValue.length > 0) {
                    collabRole = column.columnValue;
                }
            });

            if (
                (collabChoice !== null && collabRole === null)
                || (collabChoice === null && collabRole !== null)
            ) {
                alert('Veuillez sélectionner un collaborateur et un rôle');
                setLoading(false);

                return new Promise((resolve, reject) => {
                    resolve(null);
                });
            }
        }

        return advancedSearchService
            .search(searchQuery)
            .then((results) => setResults(results))
            .then(() => setFirstSearchDone(true))
            .then(() => setLoading(false));
    }, [searchQuery]);

    function getConfigurationByType(type: string): any {
        if (!(type in configurations)) {
            return null;
        }

        return configurations[type as keyof typeof configurations];
    }

    function updateFiltersFields() {
        if (searchQuery.type === undefined /*|| searchQuery.category === undefined*/) {
            setFiltersFields([]);
            return;
        }

        const rightConfiguration = getConfigurationByType(searchQuery.type as string);

        const filtersFields = [];
        for (let i = 0; i < rightConfiguration.filters.length; i++) {
            const filter: FilterType = rightConfiguration.filters[i];
            filtersFields.push({
                name: filter.value,
                label: filter.label,
                registerOptions: { required: false },
                type: filter.type || 'text',
                options: filter.options || undefined,
                placeholder: filter.placeholder || undefined,
                onChange: (value: string) => {
                    setSearchQuery(prevSearchQuery => ({
                        ...prevSearchQuery,
                        columns: [...prevSearchQuery.columns.filter(column => column.columnKey !== filter.value), {
                            columnKey: filter.value,
                            columnValue: value
                        }]
                    }));
                },
                cols: 2
            });
        }

        setFiltersFields(filtersFields as FormField[]);
    }

    const [resultsTableColumns, setResultsTableColumns] = useState<any[]>([]);
    function updateResultsTableColumns() {
        if (searchQuery.type === undefined /*|| searchQuery.category === undefined*/) {
            setResultsTableColumns([]);
            return;
        }

        const rightConfiguration = getConfigurationByType(searchQuery.type as string);
        setResultsTableColumns(rightConfiguration.resultsTableColumns);
    }

    const onExtractButtonClick = () => {
        setLoading(true);

        return advancedSearchService
            .extract(searchQuery)
            .then((link) => window.open(link, '_blank'))
            .then(() => setLoading(false));
    };

    useEffect(() => {
        updateFiltersFields();
        setResults([]);
        updateResultsTableColumns();
        // console.log('searchQuery has changed', searchQuery);
    }, [searchQuery]);

    useEffect(() => {
        // console.log('results has changed', results);
    }, [results]);
    if (!admins) {
        return <Spinner />;
    }
    return <Page>
        <Box title="Recherche avancée" transparent>
            <BoxForm
                title="Filtres"
                labelOk="C'est parti ! 🔎"
                fields={[...baseFields, ...filtersFields]}
                submit={submit}
            />

            { loading && <Spinner /> }

            {
                firstSearchDone && <Box title="Résultats" transparent className={'mt-6'}>
                    {results.length === 0 && <p className="text-sm text-gray-500">Aucun résultat</p>}
                    {

                        (results.length > 0) && (
                            <div>
                                <BoxHeader>
                                    <button type="button" className="btn btn-primary ml-3" onClick={onExtractButtonClick}>Exporter</button>
                                </BoxHeader>
                                <Table columns={ resultsTableColumns } data={ results } />
                            </div>
                        )
                    }
                </Box>
            }
        </Box>
    </Page>;
};
